import moment from 'moment'
import validator from 'validator'

export const prettyStringForEnum = (rawString) => {
    let pretty = rawString
    switch (rawString) {
        // Pretty Strings
        default:
            break
    }
    return pretty
}

export const isDatePast = (rawDate) => {
    return moment(rawDate).isBefore() // before now by default
  }

export const prettyDate = (rawDate) => {
    return moment(rawDate).format('MMMM Do YYYY')
}

export const prettyDateWithFormat = (rawDate, dateFormat = null) => {
    return moment(rawDate).format(dateFormat ? dateFormat: 'MMMM Do YYYY')
}

// Replaces <token> with 'Value'
export const tokenResolvedString = (sourceString, tokenMap) => {
    let sourceWithResolvedTokens = sourceString
    // Tokens looks like
    // {
    //     'some_email_variable': 'enduser@company.com',
    //     'another_email_variable': 'itadmin@company.com'
    // }

    for (const [token, replacementValue] of Object.entries(tokenMap)) {
        // Normalize any whitespace in the token
        // eslint-disable-next-line no-useless-escape
        const normToken = token.replace(/\s/g, '\\s')
        if (replacementValue.value) {
            sourceWithResolvedTokens = sourceWithResolvedTokens.replaceAll(RegExp(`<${normToken}>`, 'ig'), `${replacementValue.value}`)
            sourceWithResolvedTokens = sourceWithResolvedTokens.replaceAll(RegExp(`&lt;${normToken}&gt;`, 'ig'), `${replacementValue.value}`) // &lt; and &gt; escapes
        } else {
            sourceWithResolvedTokens = sourceWithResolvedTokens.replaceAll(RegExp(`<${normToken}>`, 'ig'), `${token}`)
            sourceWithResolvedTokens = sourceWithResolvedTokens.replaceAll(RegExp(`&lt;${normToken}&gt;`, 'ig'), `${token}`) // &lt; and &gt; escapes
        }
    }

    return sourceWithResolvedTokens
}

// Replaces <token> with 'Value (token)'
export const userTokenResolvedString = (sourceString, tokenMap) => {
    let sourceWithResolvedTokens = sourceString
    // Tokens looks like
    // {
    //     'End User': 'enduser@company.com',
    //     'I.T. Admin': 'itadmin@company.com'
    // }

    for (const [token, userItem] of Object.entries(tokenMap)) {
        // Normalize any whitespace in the token
        // eslint-disable-next-line no-useless-escape
        const normToken = token.replace(/\s/g, '\\s')
        if (userItem) {
            sourceWithResolvedTokens = sourceWithResolvedTokens.replaceAll(RegExp(`<${normToken}>`, 'ig'), `${userItem} (${token})`)
            sourceWithResolvedTokens = sourceWithResolvedTokens.replaceAll(RegExp(`&lt;${normToken}&gt;`, 'ig'), `${userItem} (${token})`) // &lt; and &gt; escapes
        } else {
            sourceWithResolvedTokens = sourceWithResolvedTokens.replaceAll(RegExp(`<${normToken}>`, 'ig'), `${token}`)
            sourceWithResolvedTokens = sourceWithResolvedTokens.replaceAll(RegExp(`&lt;${normToken}&gt;`, 'ig'), `${token}`) // &lt; and &gt; escapes
        }
    }

    return sourceWithResolvedTokens
}

// Expanded RFC3986 URL component encoder
export const encodeRFC3986URIComponent = (str) => {
    return encodeURIComponent(str).replace(
        /[!'()*\/]/g,
        (c) => `%${c.charCodeAt(0).toString(16).toUpperCase()}`,
    )
}

// Validation
export { validator }

// Validator Constants
export const validNameFieldMatchRegex = /^[-a-zA-ZÀ-ÖØ-öø-ÿ0-9 _@+.,'"/!&()]+$/
export const validNameFieldMaxLength = 128
export const validNameErrorMessage = `Value contains invalid characters. Only letters, numbers, spaces and _ @ + . , ' " / ! & ( ) allowed.`

export const validTextEntryMatchRegex = /^[-a-zA-ZÀ-ÖØ-öø-ÿ0-9 _\-–@+.,'‘’"“”:;?/!&()\$¥€£\r\n\*]+$/
export const validTextErrorMessage = `Value contains invalid characters. Only letters, numbers, spaces and _ - @ + . , ' " : ; ? / ! & ( ) $ ¥ € £ * allowed.`
export const emailValidationOptions = {
    allow_display_name: false,
    require_display_name: false,
    allow_utf8_local_part: true,
    require_tld: true,
    allow_ip_domain: false,
    allow_underscores: true,
    domain_specific_validation: false,
    blacklisted_chars: '',
    host_blacklist: []
}

export const validAPINodeEntryMatchRegex = /^[-a-zA-Z0-9 _@+.,'":!()]+$/ // Note: Encoded '/' (%2F) is decoded as '/' by some URL path parsers (e.g. nginx, Hasura)
export const validAPINodeErrorMessage = `Value contains invalid characters. Only letters, numbers, spaces and _ @ + . , ' " : ! ( ) allowed.`
export const validAPINodeFieldMaxLength = 128
  