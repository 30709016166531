<template>
    <v-container :class="['bg-transparent']"
        :style="{ 'height': height + 'px', 'min-height': height + 'px', 'max-height': height + 'px', 'width': width ? width + 'px': '100%'}"
        fluid>
        <v-row wrap dense justify="center" :style="{ 'height': '100%', 'opacity': 1.0 }">
            <v-col cols="12">
                <!-- <v-card v-for="i in 10" :key="i" width="100%" class="mt-2" prepend-icon="mdi-help-circle-outline">
                                <v-card-title>State Event {{ i }}</v-card-title>
                                <v-card-text>aldkjfaldkfja;lsdfja;lsdkfja;lsdkfj</v-card-text>
                            </v-card> -->
                <div class="d-flex flex-column justify-center align-center" :style="{ 'width': width ? width + 'px': '100%' }">
                    <Launcher
                    :class="[postMessageFunc !== null ? 'show-message-input' : 'hide-message-input']"
                    :style="{ 'max-height': height + 'px', 'width': width ? width + 'px': '100%' }"
                    :always-scroll-to-bottom="scrollToBottomOnUpdate"
                    :close="closeChat"
                    :colors="colors"
                    :is-open="true"
                    :message-list="userBasedIncidents"
                    :message-styling="messageStyling"
                    :new-messages-count="newMessagesCount"
                    :on-message-was-sent="onMessageWasSent"
                    :open="openChat"
                    :participants="allRoles"
                    :show-close-button="false"
                    :show-launcher="false"
                    :show-header="false"
                    :show-emoji="false"
                    :show-file="false"
                    :show-typing-indicator="showTypingIndicator"
                    :show-confirmation-deletion="false"
                    :show-edition="false"
                    :show-deletion="false"
                    :title-image-url="titleImageUrl"
                    :disable-user-list-toggle="true"
                    :placeholder="''"
                    @onType="handleOnType"
                    @edit="editMessage"
                    @remove="removeMessage">
                    <!-- <template v-slot:text-message-toolbox="scopedProps">
                        <button v-if="!scopedProps.me && scopedProps.message.type === 'text'"
                            @click.prevent="like(scopedProps.message.id)">
                            👍
                        </button>
                    </template> -->
                    <template v-slot:text-message-body="scopedProps">
                        <!-- Diagnostic messages should not render as HTML -->
                        <p v-if="scopedProps.message.diag === true" class="sc-message--text-content">{{ scopedProps.message.data.text }}</p>
                        <!-- Other messages will render HTML content -->
                        <p v-else class="sc-message--text-content" v-html="scopedProps.message.data.html"></p>
                        <!-- Image Attachments -->
                        <LazyImage
                            v-for="image in scopedProps.message.data.images"
                            :alt="image.alt"
                            :key="image.key"
                            :width="600"
                            :srcLoader="async () => { return await attachmentSourceLoaderFunc({ action: image.action, state: image.state, name: image.name, extension: image.extension }) }"
                            ></LazyImage>
                        <p v-if="scopedProps.message.data.meta" class="sc-message--meta"
                            :style="{color: scopedProps.messageColors.color}">
                            {{ scopedProps.message.data.meta }}
                        </p>
                        <p v-if="scopedProps.message.isEdited || scopedProps.message.liked" class="sc-message--edited">
                            <template v-if="scopedProps.message.isEdited">✎</template>
                            <template v-if="scopedProps.message.liked">👍</template>
                        </p>
                    </template>
                    <template v-slot:system-message-body="{message}"> [System]: {{ message.text }} </template>
                </Launcher>
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { computed, inject, ref, watch } from 'vue'
import { useDisplay } from 'vuetify'
import { tokenResolvedString, userTokenResolvedString } from '../utils/uiUtils.js'
import Launcher from '../components/vue3-beautiful-chat/Launcher.vue'
import LazyImage from '../components/LazyImage.vue'
export default {
name: 'StateEventsViewer',
    props: {
        height: {
            type: Number,
            required: false,
            default: null
        },
        isDarkTheme: {
            type: Boolean,
            required: false,
            default: true
        },
        width: {
            type: Number,
            required: false,
            default: null
        },
        testSession: {
            type: Object,
            required: false,
            default: () => {}
        },
        user: {
            type: Object,
            required: false,
            default: () => {}
        },
        scrollToBottomOnUpdate: {
            type: Boolean,
            required: false,
            default: false
        },
        postMessageFunc: {
            type: Function,
            required: false,
            default: null
        },
        attachmentSourceLoaderFunc: {
            type: Function,
            required: false,
            default: null
        },
    },
components: {
    Launcher, LazyImage
},
setup(props) {
    // Logger
    const logger = inject('vuejs3-logger')

    // Window Size Mgmt
    const { mdAndUp, smAndUp } = useDisplay()
    
    // External Links
    const visitExternalLink = (url) => {
        if (url) {
            window.open(url)
        }
    }

    // State Event (Chat View) Data
    const titleImageUrl = ref('')
    const newMessagesCount = ref(0)
    const isChatOpen = ref(false)
    const showTypingIndicator = ref('')
    const colors = ref({
        header: {
          bg: '#80acff',
          text: '#ffffff'
        },
        launcher: {
          bg: '#80acff'
        },
        messageList: {
          bg: '#ffffff'
        },
        sentMessage: {
          bg: '#80acff',
          text: '#000000'
        },
        receivedMessage: {
          bg: '#eaeaea',
          text: '#222222'
        },
        userInput: {
          bg: '#33333360',
          text: '#909090'
        }
      })
    const availableColors = ref({
        red: {
            header: {
                bg: '#D32F2F',
                text: '#fff'
            },
            launcher: {
                bg: '#D32F2F'
            },
            messageList: {
                bg: '#fff'
            },
            sentMessage: {
                bg: '#F44336',
                text: '#fff'
            },
            receivedMessage: {
                bg: '#eaeaea',
                text: '#222222'
            },
            userInput: {
                bg: '#fff',
                text: '#212121'
            },
            userList: {
                bg: '#fff',
                text: '#212121'
            }
        },
        green: {
            header: {
                bg: '#388E3C',
                text: '#fff'
            },
            launcher: {
                bg: '#388E3C'
            },
            messageList: {
                bg: '#fff'
            },
            sentMessage: {
                bg: '#4CAF50',
                text: '#fff'
            },
            receivedMessage: {
                bg: '#eaeaea',
                text: '#222222'
            },
            userInput: {
                bg: '#fff',
                text: '#212121'
            },
            userList: {
                bg: '#fff',
                text: '#212121'
            }
        },
        blue: {
            header: {
                bg: '#4e8cff',
                text: '#ffffff'
            },
            launcher: {
                bg: '#4e8cff'
            },
            messageList: {
                bg: '#ffffff'
            },
            sentMessage: {
                bg: '#4e8cff',
                text: '#ffffff'
            },
            receivedMessage: {
                bg: '#eaeaea',
                text: '#222222'
            },
            userInput: {
                bg: '#f4f7f9',
                text: '#565867'
            },
            userList: {
                bg: '#fff',
                text: '#212121'
            }
        },
        dark: {
            header: {
                bg: '#34495e',
                text: '#ecf0f1'
            },
            launcher: {
                bg: '#34495e'
            },
            messageList: {
                bg: '#2c3e50'
            },
            sentMessage: {
                bg: '#7f8c8d',
                text: '#ecf0f1'
            },
            receivedMessage: {
                bg: '#95a5a6',
                text: '#ecf0f1'
            },
            userInput: {
                bg: '#34495e',
                text: '#ecf0f1'
            },
            userList: {
                bg: '#2c3e50',
                text: '#ecf0f1'
            }
        }
    })
    const chosenColor = ref(null)
    const messageStyling = ref(true)
    const userIsTyping = ref(false)
    const roles = [
            {
                id: 'player',
                name: 'Player',
                imageUrl: require('@/assets/avatars/system.png')
            },
            {
                id: 'system',
                name: 'System',
                imageUrl: require('@/assets/avatars/system.png')
            },
            {
                id: 'me',
                name: 'User',
                imageUrl: require('@/assets/avatars/user.png')
            }
        ]
    const allRoles = ref([...roles])
    const userBasedIncidents = ref([])
    // State Event (Chat View) Functions
    const linkColor = computed(() => {
      return this.chosenColor === 'dark' ? this.colors.sentMessage.text : this.colors.launcher.bg
    })
    const backgroundColor = computed(() => {
      return this.chosenColor === 'dark' ? this.colors.messageList.bg : '#fff'
    })

    const onMessageWasSent = async (messageObject) => {
        await props.postMessageFunc({ message: messageObject?.data?.text })
    }

    const handleTyping = (/* text */) => {
    //   showTypingIndicator.value =
    //     text.length > 0 ? props.playerState.roles[props.playerState.roles.length - 1].id : ''
    }
    
    const openChat = () => {
      isChatOpen.value = true
      newMessagesCount.value = 0
    }

    const closeChat = () => {
      isChatOpen.value = false
    }

    const setColor = (color) => {
      colors.value = availableColors.value[color]
      chosenColor.value = color
    }

    const showStylingInfo = () => {
        // TODO
        //   this.$modal.show('dialog', {
        //     title: 'Info',
        //     text:
        //       'You can use *word* to <strong>boldify</strong>, /word/ to <em>emphasize</em>, _word_ to <u>underline</u>, `code` to <code>write = code;</code>, ~this~ to <del>delete</del> and ^sup^ or ¡sub¡ to write <sup>sup</sup> and <sub>sub</sub>'
        //   })
    }

    const messageStylingToggled = (e) => {
      messageStyling.value = e.target.checked
    }

    const handleOnType = () => {
        // TODO
        //   this.$root.$emit('onType')
      // userIsTyping.value = true
    }

    const editMessage = (/* message */) => {
    //   const m = messageList.value.find((m) => m.id === message.id)
    //   m.isEdited = true
    //   m.data.text = message.data.text
    }

    const removeMessage = (/* message */) => {
        // const m = this.messageList.find((m) => m.id === message.id)
        // m.type = 'system'
        // m.data.text = 'This message has been removed'
    }

    const like = (/* id */) => {
    //   const m = messageList.value.findIndex((m) => m.id === id)
    //   let msg = messageList.value[m]
    //   msg.liked = !msg.liked
    //   messageList.value.splice(m, 1, msg)
    }

    // transform all incidents so that the logged-in user's messages are on the right (i.e. appears as local user in the chat)
    // de-reference any variable / role tokens
    const processIncidentsForDisplay = (incidents) => {
        let lastMsgId = -1
        const processedIncidents = incidents?.map(incident => {
            let newMsg
            // TODO: Use placeholders for attachments until lazily loaded
            const imageAttachments = incident.data.attachments
                ?.filter((attach) => ['png', 'jpg', 'jpeg', 'gif'].includes(attach.extension))
                ?.map((image, idx) => {
                    return {
                        key: idx,
                        state: image.state ?? '',
                        action: image.action ?? '',
                        name: image.name,
                        extension: image.extension,
                        alt: `${image.name}.${image.extension}`
                    }
                }) ?? []
            if (incident.author === props.user.sub) {
                newMsg = {
                    ...incident,
                    author: 'me',
                    data: {
                        ...incident.data,
                        html: incident.data.text,
                        images: imageAttachments
                    }
                }
            } else {
                newMsg = {
                    ...incident,
                    data: {
                        ...incident.data,
                        html: incident.data.text,
                        images: imageAttachments
                    }
                }
            }

            // De-reference any <variable> tokens in the item's 'text' field
            // De-reference any <role> tokens in the item's 'text' field
            newMsg.data.html = newMsg.data.html ? userTokenResolvedString(tokenResolvedString(newMsg.data.html, props.testSession.variables), props.testSession.roleAssignments) : undefined
            lastMsgId = incident.id
            return newMsg
        }) ?? []

        // Add "..." typing message if the current user is the current player
        if (props.user?.email === props.testSession?.currentPlayerEmail
            && props.testSession?.status === 'ONGOING') {
            processedIncidents.push({ type: 'text', author: 'me', id: lastMsgId + 1, data: { text: '', html: '...' }, suggestions: [] })
        }
        userBasedIncidents.value = processedIncidents
    }

    // State Event Watchers
    watch(props.testSession, (val) => {
      logger.debug(`EventsView: Test Session changed!`)
      if (val) {
        // Process the raw incident details items for display
        processIncidentsForDisplay(val.incidentDetails)

        // Update roles
        allRoles.value = [...roles].concat(Object.values(val.userCache).map(user => {
            return {
                id: user.id,
                name: user.email,
                imageUrl: user.picture
            }
        }))
      }
    }, { immediate: true })

    watch(props.user, () => {
      logger.debug(`User changed!`)
      // re-process incidents
      processIncidentsForDisplay(props.testSession.incidentDetails)
    }, { immediate: true })

    return {
        // Functions
        visitExternalLink,
        // Window Size Management
        smAndUp,
        mdAndUp,
        // Events View Data
        titleImageUrl,
        newMessagesCount,
        isChatOpen,
        showTypingIndicator,
        colors,
        availableColors,
        chosenColor,
        messageStyling,
        allRoles,
        userBasedIncidents,
        userIsTyping,
        // Event View Functions
        linkColor,
        backgroundColor,
        onMessageWasSent,
        handleTyping,
        openChat,
        closeChat,
        setColor,
        showStylingInfo,
        messageStylingToggled,
        handleOnType,
        editMessage,
        removeMessage,
        like
    }
}
}
</script>

<style lang="scss" scoped>

:deep(.sc-chat-window) {
  width: unset !important;
  height: unset !important;
  // max-height: unset !important;
  position: relative !important; 
  right: unset !important;
  bottom: unset !important;
  box-sizing: border-box;
  box-shadow: 0px 7px 40px 2px rgba(148, 149, 150, 0.1);
  background-color: transparent !important;
  background: unset !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 10px !important;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  animation: fadeIn;
  animation-duration: 0.3s;
  animation-timing-function: ease-in-out;
}
:deep(.sc-chat-window.closed) {
  opacity: 0;
  display: none;
  bottom: 90px;
}

:deep(.sc-message-list) {
  height: unset !important;
  width: 100% !important;
  overflow-y: auto;
  background-size: 100%;
  background-color: transparent !important;
  padding: 40px 0px;
}

:deep(.sc-message) {
    width: unset !important;
}

.hide-message-input {
    :deep(.sc-user-input) {
        display: none !important;
    }
}

.show-message-input {
    :deep(.sc-user-input) {
        border: medium solid #606060;
    }
}

:deep(.sc-user-input--text) {
    width: 500px !important;
}

:deep(.sc-message--text-content > a) {
    color: #777 !important;
}

:deep(.sc-message--text-content) {
    white-space: pre-wrap;
}

</style>